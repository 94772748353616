<template>
  <!-- begin:: Aside -->
  <div
    class="brand flex-column-auto p-2 elevation-3"
    style="background-color: #151b2c !important; z-index: 2 !important"
  >
    <div class="brand-logo" width="100%">
      <router-link :to="{ name: 'dashboard.dashboardAnalytics' }">
        <!-- <h1 class="white--text px-6" style="color: white;">IQ</h1> -->
        <div>
          <img
            class="d-block"
            width="100%"
            src="/img/iqplatform.png"
            alt="Logo"
          />
        </div>
      </router-link>
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss" scoped>
.aside-toggle {
  // outline: none;
}
#kt_brand {
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTBrand",

  methods: {},
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
