<template>
  <router-link
    :to="{ name: item.name }"
    v-slot="{ href, navigate, isActive, isExactActive }"
    custom
  >
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item poppins"
      @click.passive="hideSideBar()"
      :class="[isExactActive && 'menu-item-active']"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <v-icon>{{ item.icon }}</v-icon>
        <span class="menu-text ml-3">{{ item.title }}</span>
      </a>
    </li>
  </router-link>
</template>

<script>
import eventBus from "@/core/services/eventBus";
export default {
  name: "Navi",
  props: ["item"],
  methods: {
    hideSideBar() {
      eventBus.$emit("toggleAside");
    },
  },
};
</script>
