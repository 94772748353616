<template>
  <router-link
    to="/onboarding/complete_profile"
    @click.native="closeOffcanvas"
    href="/onboarding/complete_profile"
    class="navi-item mx-4"
  >
    <div class="navi-link prof_complete_guide">
      <v-progress-linear
        :value="profileCompleteness"
        color="#8950fc"
        height="25"
        rounded
      >
        <template v-slot:default="{ value }">
          <strong>{{ value }}%</strong>
        </template>
      </v-progress-linear>
      <!--      <div class="symbol symbol-40 bg-light mr-3">-->
      <!--        <div class="symbol-label">-->
      <!--          <span class="svg-icon svg-icon-md svg-icon-success">-->
      <!--            <v-icon>mdi-account-check</v-icon>-->
      <!--          </span>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="navi-text">
        <router-link to="/onboarding/complete_profile">
          <div class="font-weight-bold text-black-50">
            Click to add information to your profile
          </div>
        </router-link>
      </div>
    </div>
  </router-link>
  <!--end:Item-->
</template>
<script>
import KTOffcanvas from "@/assets/js/components/offcanvas";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user";

export default {
  name: "ProfileCompleteness",
  methods: {
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    profileCompleteness() {
      return this.$store.getters.getProfileCompleteness;
    },
  },
};
</script>
