var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-nav",attrs:{"id":_vm.item.title}},[_c('div',{staticClass:"menu-section w-100 ma-0 menu-title"},[_c('inline-svg',{staticClass:"px-1",attrs:{"width":"40px","height":"40px","src":_vm.item.svg}}),_c('h2',{staticClass:"font-weight-bolder pt-1"},[_vm._v(_vm._s(_vm.$t(_vm.item.title)))])],1),_c('v-list',{staticClass:"mt-4 px-5 poppins font-weight-medium"},[_vm._l((_vm.item.entities),function(group){return [(group?.items)?_c('v-list-group',{key:group.title,attrs:{"no-action":""},scopedSlots:_vm._u([(group.items.length)?{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"menu-item text-dark-50 font-weight-bolder",domProps:{"textContent":_vm._s(_vm.$t(group.title))}})],1)]},proxy:true}:null],null,true)},[(group?.items)?_vm._l((group.items),function(child){return _c('v-list-item',{key:child.title,staticClass:"menu-link",class:{ 'bg-light-info': _vm.activeSubmenu === child.name },attrs:{"to":{ name: child.name },"exact":""},on:{"click":() => {
                _vm.$router.push({ name: child.name });
                _vm.activeSubmenu = child.name;
                _vm.hideSideBar();
              }}},[_c('span',{staticClass:"menu-text ml-3"},[_vm._v(" "+_vm._s(_vm.$t(child.title))+" ")])])}):_vm._e()],2):_c('v-list-item',{key:group.title,staticClass:"menu-link",class:{ 'bg-light-info': _vm.activeSubmenu === group.name },attrs:{"to":{ name: group.name },"exact":""},on:{"click":() => {
            _vm.$router.push({ name: group.name });
            _vm.activeSubmenu = group.name;
            _vm.hideSideBar();
          }}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.$t(group.title))+" ")])])]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }