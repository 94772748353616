<template>
  <div class="topbar-item" v-if="currentUser.data">
    <div
      class="btn btn-icon w-auto d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
      :class="{ 'btn-clean': !isCustomStyle }"
    >
      <span
        class="font-weight-bold font-size-base d-none d-md-inline mr-1"
        :class="{ 'text-white': isCustomStyle, 'text-muted': !isCustomStyle }"
      >
        Hi,
      </span>
      <span
        class="font-weight-bolder font-size-base d-none d-md-inline mr-3"
        :class="{ 'text-white': isCustomStyle, 'text-dark-50': !isCustomStyle }"
      >
        {{ currentUser.data.name }}
      </span>
      <span class="symbol symbol-35 symbol-light-info">
        <img
          v-if="currentUser.data.photo.url"
          alt="image"
          :src="currentUser.data.photo.url"
        />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.data.photo.first_letter.toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pt-10"
      >
        <h3 class="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-info"
          id="kt_quick_user_close"
        >
          <v-icon>mdi-close</v-icon>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img
              v-if="currentUser.data.photo.url"
              :alt="currentUser.data.name"
              :src="currentUser.data.photo.url"
            />
            <span v-else class="symbol symbol-35 symbol-light-info">
              <span class="symbol-label font-size-h2 font-weight-bold">
                {{ currentUser.data.photo.first_letter.toUpperCase() }}
              </span>
            </span>
          </div>
          <div class="d-flex flex-column">
            <router-link
              to="/profile"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-info"
            >
              {{ currentUser.data.name }}
            </router-link>
            <div class="text-muted mt-1">{{ organization }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-text text-muted text-hover-info">
                    {{ currentUser.data.email }}
                  </span>
                </span>
              </a>
            </div>
            <button
              class="btn btn-light-info btn-bold poppins-ls"
              @click="onLogout"
            >
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            to="/profile"
            @click.native="closeOffcanvas"
            href="/profile"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <v-icon color="#8950fc">mdi-information-outline</v-icon>
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <router-link to="/profile">
                  <div class="font-weight-bold text-info">My Profile</div>
                </router-link>
                <div class="text-muted">
                  Account settings and more
                  <span
                    class="label label-light-danger label-inline font-weight-bold"
                  >
                    update
                  </span>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  props: ["customStyle"],
  data() {
    return {};
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    isCustomStyle: function() {
      return this.customStyle ? true : false;
    },
    ...mapGetters(["currentUser"]),
    organization: function() {
      return this.$store.getters.currentUser.data.organization_name;
    },
  },
};
</script>
